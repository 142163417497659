<template>
  <div>
    <header class="w-full" id="carousel">
      <Carousel
        :perPage="1"
        :paginationPadding="5"
        paginationActiveColor="#8a5e31"
        paginationColor="#b89e83"
        :autoplay="false"
        class="w-full h-full"
      >
        <Slide v-for="url in header_urls" v-bind:key="url">
          <img :src="url" id="carousel-image" class="" alt="Restaurant image" />
        </Slide>
      </Carousel>
    </header>

    <!-- <section id="reserveer" class="px-4 py-8 md:px-8">
      <button
        @click="openForm()"
        class="px-4 py-2 text-3xl text-black transition-all duration-300 ease-in-out border-primary focus:outline-none font-display lg:text-4xl shadow-dark text-primary hover:opacity-75"
      >
        Reserveer hier
      </button>
      <div class="flex flex-row items-center justify-center mb-8">
        <div class="hidden w-full h-px bg-cognac md:block"></div>
        <h1
          class="w-full mx-4 text-3xl text-center uppercase lg:text-4xl font-display text-cognac"
        >
          Takeaway
        </h1>
        <div class="hidden w-full h-px bg-cognac md:block"></div>
      </div>
      <div class="p-4 m-auto md:w-1/2 lg:w-2/5">
        <p class="mt-8 text-center">
          Door Covid-19 zijn wij niet open. Wel kunt u op woensdag tot en met
          zaterdag bij ons bestellen. Bekijk onze dagschotel menu's voor de
          gerechten. Email of bel ons voor 17 uur met uw bestelling.
        </p>
      </div>
    </section> -->

    <section id="wijn" class="px-4 py-8 md:px-8">
      <div class="flex flex-row items-center justify-center mb-8">
        <div class="hidden w-full h-px bg-cognac md:block"></div>
        <h1
          class="w-full mx-4 text-3xl text-center uppercase lg:text-4xl font-display text-cognac"
        >
          Samenwerkingen
        </h1>
        <div class="hidden w-full h-px bg-cognac md:block"></div>
      </div>
      <div class="p-4 m-auto md:w-1/2 lg:w-2/5">
        <p class="mt-8 text-center">
          MEYER Wines & Bites werkt samen met: Karakter Wijnimport uit Amsterdam, Sauter Wijnen uit Bussum/Maastricht en Bier & Co als leveranciers van onze dranken.
        </p>
      </div>
    </section>

    <section id="wines-and-bites" class="px-4 py-8 md:px-8">
      <div class="flex flex-row items-center justify-center mb-8">
        <div class="hidden w-full h-px bg-cognac md:block"></div>
        <h1
          class="w-full mx-4 text-3xl text-center uppercase lg:text-4xl font-display text-cognac"
        >
          Wines & Bites
        </h1>
        <div class="hidden w-full h-px bg-cognac md:block"></div>
      </div>
      <div class="relative my-2" id="menu-images">
        <img src="/images/kurk.jpg" class="cork-image" alt="cork image" />
        <!-- <img
          :src="menu_urls[0]"
          @click="openModal(0)"
          class="transition-all duration-300 ease-in-out menu-image-1"
          alt="cork image"
        /> -->
        <img
          :src="menu_urls[0]"
          @click="openModal(0)"
          class="transition-all duration-300 ease-in-out menu-image-2"
          alt="cork image"
        />
        <img
          :src="menu_urls[1]"
          @click="openModal(1)"
          class="transition-all duration-300 ease-in-out menu-image-3"
          alt="cork image"
        />
        <!-- <img
          :src="menu_urls[3]"
          @click="openModal(3)"
          class="transition-all duration-300 ease-in-out menu-image-4"
          alt="cork image"
        /> -->
      </div>
    </section>

    <!-- <section id="takeaway" class="px-4 py-8 md:px-8">
      <div class="flex flex-row items-center justify-center mb-8">
        <div class="hidden w-full h-px bg-cognac md:block"></div>
        <h1
          class="w-full mx-4 text-3xl text-center uppercase lg:text-4xl font-display text-cognac"
        >
          Dagschotel Menu
        </h1>
        <div class="hidden w-full h-px bg-cognac md:block"></div>
      </div>
      <div class="grid grid-cols-1 gap-4 lg:grid-cols-2" v-if="takeaway.length">
        <TakeawayItem
          v-for="course in takeaway"
          v-bind:key="course.created_on"
          :from="course.from_date"
          :to="course.to_date"
          :description="course.description"
          :price="course.price"
          :image="course.image"
        />
      </div>
      <div class="text-center" v-if="!takeaway.length">
        Er zijn geen dagschotels gepland
      </div>
      <div class="w-full mx-auto mt-12 text-center md:w-3/5">
        Graag bestellen vòòr 17:00 uur op e-mailadres:
        <a
          href="mailto:info@meyerwinesandbites.nl"
          class="text-gray-700 hover:opacity-75"
          >info@meyerwinesandbites.nl</a
        >
        of app naar
        <a href="tel:0653198670" class="text-gray-700 hover:opacity-75"
          >06 53 19 86 70</a
        >
      </div>
      <div></div>
    </section> -->

    <section id="calendar" class="px-4 py-8 md:px-8">
      <div class="flex flex-row items-center justify-center mb-8">
        <div class="hidden w-full h-px bg-cognac md:block"></div>
        <h1
          class="w-full mx-4 text-3xl text-center uppercase lg:text-4xl font-display text-cognac"
        >
          Agenda
        </h1>
        <div class="hidden w-full h-px bg-cognac md:block"></div>
      </div>
      <div class="grid grid-cols-1 gap-4 lg:grid-cols-2" v-if="events.length">
        <EventItem
          v-for="event in events"
          v-bind:key="event.id"
          :title="event.title"
          :description="event.description"
          :date="event.date"
          :image="event.image"
          :start_time="event.start_time"
          :end_time="event.end_time"
        />
      </div>
      <div class="text-center" v-if="!events.length">
        Er zijn geen evenementen gepland
      </div>
    </section>

    <section id="contact" class="pt-16 bg-gray-400">
      <div class="flex flex-row items-center justify-center mx-8">
        <div class="hidden w-full h-px bg-cognac md:block"></div>
        <h1
          class="text-3xl text-center uppercase lg:text-4xl md:w-1/2 font-display text-cognac"
        >
          Contact
        </h1>
        <div class="hidden w-full h-px bg-cognac md:block"></div>
      </div>
      <div class="my-2">
        <h1 class="px-4 font-bold text-center uppercase text-primary">Adres</h1>
        <p class="text-center text-primary">Marktstraat 41</p>
        <p class="text-center text-primary">1411 CZ Naarden-Vesting</p>
        <p class="text-center text-primary">
          <a href="tel:0031653198670" class="hover:opacity-75"
            >+31 (0)6 53 19 86 70</a
          >
        </p>
        <p class="text-center text-primary">
          <a href="mailto:info@meyerwinesandbites.nl" class="hover:opacity-75"
            >info@meyerwinesandbites.nl</a
          >
        </p>
      </div>
      <div class="px-4 m-auto my-8">
        <h1 class="font-bold text-center uppercase text-primary">
          Openingstijden
        </h1>
        <p class="text-center text-primary">
          Maandag t/m donderdag vanaf 16:00 – 22:00
        </p>
        <p class="text-center text-primary">
          Vrijdag vanaf 15:00 – 22:00
        </p>
        <p class="text-center text-primary">
          Zaterdag vanaf 15:00 – 22:30
        </p>
        <p class="text-center text-primary">
          Zondag vanaf 15:00 – 22:00
        </p>
      </div>
      <img src="/images/wijn.png" alt="wijn" class="m-auto xl:w-1/2" />
    </section>

    <section id="socials">
      <div
        class="flex flex-col items-center justify-around w-11/12 p-4 mx-auto my-16 border-primary md:w-3/5 md:p-8 shadow-dark"
      >
        <div class="text-3xl uppercase font-display text-cognac">
          Volg ons op
        </div>
        <div class="flex flex-row items-center justify-center">
          <a
            href="https://www.instagram.com/meyer.winesandbites/"
            target="_blank"
            ><img
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzhhNWUzMSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJmZWF0aGVyIGZlYXRoZXItaW5zdGFncmFtIiB2aWV3Qm94PSIwIDAgMjQgMjQiPgogIDxyZWN0IHg9IjIiIHk9IjIiIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgcng9IjUiIHJ5PSI1Ii8+CiAgPHBhdGggZD0iTTE2IDExLjM3QTQgNCAwIDExMTIuNjMgOCA0IDQgMCAwMTE2IDExLjM3ek0xNy41IDYuNWguMDEiLz4KPC9zdmc+"
              alt="Instagram"
              class="w-8 h-8 m-2 transition-all duration-300 ease-in-out hover:opacity-75"
          /></a>
          <a href="https://www.facebook.com/meyerwinesandbites/" target="_blank"
            ><img
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzhhNWUzMSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJmZWF0aGVyIGZlYXRoZXItZmFjZWJvb2siIHZpZXdCb3g9IjAgMCAyNCAyNCI+CiAgPHBhdGggZD0iTTE4IDJoLTNhNSA1IDAgMDAtNSA1djNIN3Y0aDN2OGg0di04aDNsMS00aC00VjdhMSAxIDAgMDExLTFoM3oiLz4KPC9zdmc+"
              alt="Facebook"
              class="w-8 h-8 m-2 transition-all duration-300 ease-in-out hover:opacity-75"
          /></a>
        </div>
        <div
          class="grid grid-flow-row grid-cols-1 gap-4 mt-4 md:grid-cols-2 xl:grid-cols-3"
        >
          <div
            class="w-2/3 m-auto transition-all duration-300 ease-in-out transform bg-white flex items-center h-2/3 md:w-full md:h-full md:m-0 lg:w-64 lg:h-64 xl:h-40 xl:w-40 hover:scale-110"
            v-for="url in image_urls"
            v-bind:key="url"
          >
            <img :src="url" alt="Een van onze insta foto's" />
          </div>
        </div>
      </div>
    </section>
    <footer class="p-4 text-xs text-center bg-primary text-cognac-light">
      © 2020 Meyer |
      <a href="https://meyerwinesbites.nl" class="hover:underline"
        >Wines & Bites</a
      >
      / Design:
      <a href="https://ccfactory.nl/" target="_blank" class="hover:underline"
        >CC Factory</a
      >
      / Realisatie:
      <a href="https://egidius.io" target="_blank" class="hover:underline"
        >Egidius IT</a
      >
    </footer>

    <modal
      name="menu-modal"
      :width="image_modal_width"
      height="auto"
      :scrollable="true"
    >
      <div class="flex flex-col items-center justify-items-center">
        <div class="flex flex-row items-center justify-between w-full p-3">
          <div class="text-2xl font-bold">{{ menu_title }}</div>
          <div
            @click="closeModal()"
            class="flex flex-row justify-center p-1 border border-gray-500 rounded-lg cursor-pointer group"
          >
            <svg
              class="text-black fill-current group-hover:opacity-25"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              ></path>
            </svg>
          </div>
        </div>
        <img
          :src="menu_urls[modal_image]"
          alt="Menu afbeelding"
          class="max-h-full m-auto"
        />
      </div>
    </modal>

    <modal
      name="form-modal"
      :width="form_modal_width"
      height="auto"
      :scrollable="true"
    >
      <ReservationForm>
        <template v-slot:top-close-button>
          <div
            @click="closeForm()"
            class="flex flex-row justify-center p-1 border border-gray-500 rounded-lg cursor-pointer group"
          >
            <svg
              class="text-black fill-current group-hover:opacity-25"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              ></path>
            </svg>
          </div>
        </template>
        <template v-slot:close-button>
          <button
            @click="closeForm()"
            class="px-2 py-1 mx-2 text-sm text-white bg-red-500 border-4 border-red-500 rounded hover:bg-red-700 hover:border-red-700"
            type="button"
          >
            Sluiten
          </button>
        </template>
      </ReservationForm>
    </modal>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import ReservationForm from "@/components/ReservationForm";
import EventItem from "@/components/EventItem";
//import TakeawayItem from "@/components/TakeawayItem";

export default {
  name: "Navbar",
  components: {
    Carousel,
    Slide,
    ReservationForm,
    EventItem
    //TakeawayItem
  },
  data() {
    return {
      modal_image: 0,
      menu_title: "Menu 1",
    };
  },
  computed: {
    image_urls() {
      return this.$store.state.images;
    },
    menu_urls() {
      return this.$store.state.menus;
    },
    header_urls() {
      return this.$store.state.headers;
    },
    events() {
      let events = this.$store.state.events;
      return events.sort(function (a, b) {
        var c = new Date(a.date);
        var d = new Date(b.date);
        return c - d;
      });
    },
    takeaway() {
      let takeaway = this.$store.state.takeaway;
      return takeaway.sort(function (a, b) {
        var c = new Date(a.from_date);
        var d = new Date(b.from_date);
        return c - d;
      });
    },
    image_modal_width() {
      var vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );

      if (vw < 300) {
        return "80%";
      } else if (vw < 500) {
        return "70%";
      } else if (vw < 800) {
        return "50%";
      } else if (vw < 1200) {
        return "40%";
      } else if (vw < 1500) {
        return "35%";
      }

      return "30%";
    },
    form_modal_width() {
      var vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );

      if (vw < 300) {
        return "90%";
      } else if (vw < 500) {
        return "80%";
      } else if (vw < 800) {
        return "70%";
      } else if (vw < 1200) {
        return "60%";
      }

      return "50%";
    },
  },
  methods: {
    openModal(image) {
      console.log(image)
      if (image === 0) {
        window.open("https://www.meyerwinesandbites.nl/menu.pdf", "_blank")
        return
      }

      if (image === 1) {
        window.open("https://www.meyerwinesandbites.nl/menu.pdf", "_blank")
        return
      }
      //this.modal_image = image;
      //this.menu_title = "Menu " + (image + 1);
      //this.$modal.show("menu-modal");
    },
    closeModal() {
      this.$modal.hide("menu-modal");
    },
    openForm() {
      this.$modal.show("form-modal");
    },
    closeForm() {
      this.$modal.hide("form-modal");
    },
  },
  beforeMount() {
    this.$store.dispatch("getImages");
    this.$store.dispatch("getMenus");
    this.$store.dispatch("getHeaders");
    this.$store.dispatch("getEvents");
  },
  mount() {
    this.show();
  },
};
</script>
